<template>
  <div class="left-4 top-[130px] flex w-full flex-shrink-0 items-center">
    <section
      v-if="
        isAppLoading || isFetchingBoards || boards.length > 0 || hasPlanInvites || hasInvitations
      "
      class="mr-4 flex h-9 items-center rounded-lg bg-agentnoon-primary-700 p-1 pr-1.5 shadow-lg"
    >
      <p class="ml-1.5 mr-2 text-sm text-white">Instance</p>
      <BoardSelection />
    </section>

    <PopperTooltip
      v-if="isAgentnoonAccount"
      content="Add instance"
      placement="right"
      hover
      open-delay="300"
      style="border-width: 0; margin: 0"
    >
      <button
        class="flex items-center justify-center text-sm font-medium text-gray-500 transition-colors hover:text-bluedark-700"
        @click="showCreateBoardModal = true"
      >
        <div class="flex h-7 w-7 items-center justify-center rounded-full bg-agentnoon-primary-700">
          <PlusIcon class="h-4 w-4 text-white" />
        </div>
      </button>
    </PopperTooltip>

    <CreateBoard
      v-if="isAgentnoonAccount"
      :show="showCreateBoardModal"
      @close="showCreateBoardModal = false"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import BoardSelection from '@/components/BoardSelection.vue'
import CreateBoard from './CreateBoard.vue'
const showCreateBoardModal = ref(false)
const { getters } = useStore()
import { PlusIcon } from '@heroicons/vue/outline'
import useBoard from '@/hooks/use-board'

const user = computed(() => getters.user)
const isAgentnoonAccount = computed(() => {
  return (
    user.value.email.includes('@agentnoon.com') ||
    user.value.email.includes('@alignorg.com') ||
    user.value.email === 'agentnoon+test@csisolar.co' ||
    user.value.email === 'sanjeev.tiwari@es.nestle.com'
  )
})

const isAppLoading = computed(() => getters.isAppLoading)
const isFetchingBoards = computed(() => getters.isFetchingBoards)
const hasInvitations = computed(() => getters.hasPendingInvitations)
const { boards } = useBoard()
const hasPlanInvites = computed(() => getters.hasUserPlanInvites)

/**
 * Handles the submission of the profile form.
 * @param {string} fullName - The full name entered in the form.
 */
</script>
