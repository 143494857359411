<template>
  <button
    class="shadow-xs flex flex-row items-center justify-center gap-1 rounded-lg px-4 py-2 text-sm font-medium transition-colors focus:outline-0 focus:ring-4"
    :class="{
      'bg-bluedark-600 text-white ring-bluedark-100 hover:bg-bluedark-700 focus:border-bluedark-100 disabled:bg-bluedark-200':
        color === 'bluedark',
      'bg-error-600 text-white ring-error-100 hover:bg-error-700 focus:border-error-100 disabled:bg-error-200':
        color === 'error',
      'bg-success-600 text-white ring-success-100 hover:bg-success-700 focus:border-success-100 disabled:bg-success-200':
        color === 'success',
      'border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus:ring-gray-50 active:bg-gray-100 disabled:opacity-50':
        color === 'white',
      'border border-error-500 bg-white text-error-900 ring-error-50 hover:bg-error-50 active:bg-error-100 disabled:opacity-50':
        color === 'white-error',
      'bg-bluedark-50 text-bluedark-700 hover:bg-bluedark-100 focus:border-transparent focus:ring-0 disabled:bg-bluedark-25 disabled:text-bluedark-300':
        color === 'blue-light',
      'bg-white text-bluedark-700 hover:bg-bluedark-50 focus:border-transparent focus:ring-0 disabled:bg-bluedark-25 disabled:text-bluedark-300':
        color === 'blue-transparent',
      'bg-white text-gray-700 hover:bg-gray-50 focus:border-transparent focus:ring-0 disabled:bg-gray-25 disabled:text-gray-400':
        color === 'gray-transparent',
      'bg-error-50 text-error-700 hover:bg-error-50 focus:border-transparent focus:ring-0 disabled:bg-error-25 disabled:text-error-300':
        color === 'red-light',
      'bg-green-50 text-green-700 hover:bg-green-50 focus:border-transparent focus:ring-0 disabled:bg-green-25 disabled:text-green-300':
        color === 'green-light',
      'bg-primary-900 text-white ring-primary-300 hover:bg-primary-700': color === 'primary',
      'bg-agentnoon-primary-700 text-white hover:opacity-90': color === 'agentnoon-primary'
    }"
  >
    <slot></slot>
    <Spinner v-if="isLoading" :color="getLoaderColor()" />
  </button>
</template>

<script setup>
import Spinner from './SpinnerComp.vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'bluedark',
    validator: (val) =>
      [
        'bluedark',
        'error',
        'white',
        'white-error',
        'blue-light',
        'green-light',
        'red-light',
        'success',
        'primary',
        'blue-transparent',
        'gray-transparent'
      ].includes(val)
  }
})

/**
 * Gets the color for the loader based on the provided props.
 *
 * @returns {string} The color to be used for the loader.
 */
const getLoaderColor = () => {
  // If the provided color is 'error' or 'success', return that color.
  // Otherwise, return 'white' as the default color for the loader.
  return props.color === 'error' || props.color === 'success' ? props.color : 'white'
}
</script>
