import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const getDb = () => {
  if (!firebase.apps.length) {
    throw new Error('Firebase not initialized')
  }
  return firebase.firestore()
}

export async function addRecentInvite(currentUserUid, invitedPerson) {
  try {
    const db = getDb()
    const recentInvitesRef = db.collection('recentInvites').doc(currentUserUid)
    
    // Get current invites
    const doc = await recentInvitesRef.get()
    let invites = doc.exists ? doc.data().invites : []
    
    // Remove if person already exists
    invites = invites.filter(p => p.uid !== invitedPerson.uid)
    
    // Add to beginning and keep only 3
    invites.unshift({
      uid: invitedPerson.uid,
      name: invitedPerson.name,
      email: invitedPerson.email,
    })
    
    if (invites.length > 3) {
      invites = invites.slice(0, 3)
    }
    
    // Save to Firestore
    await recentInvitesRef.set({ invites })
  } catch (error) {
    console.error('Error adding recent invite:', error)
    Sentry?.captureException(error)
  }
}

export async function getRecentInvites(currentUserUid) {
  try {
    const db = getDb()
    const doc = await db.collection('recentInvites').doc(currentUserUid).get()
    return doc.exists ? doc.data().invites : []
  } catch (error) {
    console.error('Error getting recent invites:', error)
    Sentry?.captureException(error)
    return []
  }
}