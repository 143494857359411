<template>
  <Combobox v-model="selectedAccount" as="div">
    <div v-if="mainBoards && mainBoards.length > 0" class="relative">
      <ComboboxInput
        class="focus:border-agentnoon-primary-700 focus:ring-agentnoon-primary-700 text-agentnoon-primary-700 my-0.5 h-6 w-full rounded-full border border-gray-300 bg-bluedark-50 pl-3 pr-4 shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
        :display-value="() => selectedAccountName"
        @change="query = $event.target.value"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center justify-between rounded-r-md px-4 text-sm focus:outline-none"
      >
        <ChevronDownIcon class="text-agentnoon-primary-700 h-5 w-5" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filtered.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="board in filtered"
          :key="board.boardId"
          v-slot="{ active, selected }"
          :value="board.boardId"
          as="template"
        >
          <li
            :class="[
              'relative cursor-default select-none py-0.5 pl-3 pr-9',
              active ? 'bg-agentnoon-primary-700 text-white' : 'text-gray-900'
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ board.companyName }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-agentnoon-primary-700'
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import useBoard from '@/hooks/use-board'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const { getters } = useStore()
const { push } = useRouter()
const { currentBoard, changeCurrentBoard } = useBoard()

const query = ref('')
const filtered = computed(() =>
  query.value === ''
    ? mainBoards.value
    : mainBoards.value.filter((board) => {
        return board.companyName.toLowerCase().includes(query.value.toLowerCase())
      })
)

const mainBoards = computed(() => {
  const result = [...getters.mainBoards]
  return result.sort((a, b) => a.companyName.localeCompare(b.companyName))
})

const selectedAccount = computed({
  get: () => currentBoard.value,
  set: async (value) => {
    changeCurrentBoard(value)

    await push({ name: 'Landing', params: { boardId: value }, force: true })
  }
})

const selectedAccountName = computed(() => {
  const board = mainBoards.value.find((board) => {
    return board.boardId === selectedAccount.value
  })

  return board?.companyName
})
</script>
