import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service'
import { decompressBufferToJsonFflate, decompressBufferFflate } from '@/utils/Utils'

/**
 * Delete a scope on a board given a scopeId.
 * @returns {Promise} true for success, false otherwise
 */
export const deleteAccessScope = async ({ boardId, scopeId, targetUid }) => {
  try {
    const ret = await axios.delete(
      `${API_URL}/access-scopes/${boardId}/scope/${scopeId}/${targetUid}`,
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * Delete all access scopes for a user in a board.
 * @returns {Promise} true for success, false otherwise
 */
export const deleteAccessScopes = async ({ boardId, targetUid }) => {
  try {
    const ret = await axios.delete(`${API_URL}/access-scopes/${boardId}/scope/${targetUid}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * Update or create new access scopes for a user on a specific board.
 *
 * @param {Object} options - An object containing the parameters for the update.
 * @param {string} options.targetUid - The unique identifier of the target user.
 * @param {string} options.accessScopes - All the user's access scopes to be inserted to DB.
 * @param {string} options.boardId - The unique identifier of the board to update.
 * @returns {Promise} Return all access scopes for the user on the target board
 */
export const updateAccessScopes = async ({ targetUid, accessScopes, boardId }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/access-scopes/${boardId}`,
      { data: { targetUid, accessScopes } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      return ret.data
    } else {
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * Fetch the list of discrete unique data of the people, independent of the user's access scopes.
 */
export const fetchAccessScopesMeta = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/meta`, {
      responseType: 'arraybuffer',
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return decompressBufferToJsonFflate(ret.data)
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetch the list of discrete unique data of the people, independent of the user's access scopes.
 */
export const fetchAccessScopesUsers = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/users`, {
      responseType: 'arraybuffer',
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return decompressBufferToJsonFflate(ret.data)
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Return true if user can view management cost.
 */
export const fetchCanViewManagementCost = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/management-cost/view`, {
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Return consolidated view/edit permissions of each attribute from the user's access scopes
 */
export const fetchFieldPermissions = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/field-permissions`, {
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Return access groups fpr a board
 */
export const fetchAccessGroups = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/access-groups`, {
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Create an access group fpr a board
 */
export const createAccessGroup = async ({ boardId, accessScope }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/access-scopes/${boardId}/${accessScope.scopeId}/create-group`,
      { data: { accessScope } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Update an access group fpr a board.
 * Pass the entire access scope object to update the access group.
 */
export const updateAccessGroup = async ({ boardId, accessScope }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/access-scopes/${boardId}/${accessScope.scopeId}/update-group`,
      { data: { accessScope } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Delete an access group fpr a board.
 */
export const deleteAccessGroup = async ({ boardId, scopeId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/access-scopes/${boardId}/${scopeId}/delete-group`, {
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Export access logs as a CSV file.
 * @param {string} boardId - The unique identifier of the board to export.
 * @returns {Promise} true for success, false otherwise
 */
export const exportAccessLogsCsv = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/access-scopes/${boardId}/export-csv`, {
      headers: { ...(await getAuthHeader()) },
      responseType: 'arraybuffer' // Important for file downloads
    })

    if (ret.status === 200) {
      const data = decompressBufferFflate(ret.data) || []
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
