import { addRecentInvite, getRecentInvites } from '@/services/recent-invites.service'

const state = {
    recentInvites: []
  }
  
  const mutations = {
    SET_RECENT_INVITES(state, invites) {
      state.recentInvites = invites
    }
  }
  
  const actions = {
    async fetchRecentInvites({ commit, rootGetters }) {
      try {
        const invites = await getRecentInvites(rootGetters.user.uid)
        commit('SET_RECENT_INVITES', invites)
      } catch (error) {
        console.error('Error fetching recent invites:', error)
      }
    },

    async addRecentInvite({ dispatch, rootGetters }, person) {
      try {
        await addRecentInvite(rootGetters.user.uid, person)
        await dispatch('fetchRecentInvites')
      } catch (error) {
        console.error('Error adding recent invite:', error)
      }
    }
  }
  
  const getters = {
    recentInvites: state => state.recentInvites
  }
  
  export default {
    state,
    mutations,
    actions,
    getters
  }