import { createIdbPersister } from '@/lib/QueryPersisterIdb'
import { getMeta } from '@/services/meta-api.service'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import { useStore } from 'vuex'

/**
 * @param {string} projectId - pass to load data in the context of a specific project. If the board is in a project already, this parameter is ignored.
 */
export function useMeta(boardId, projectId = null) {
  const queryClient = useQueryClient()
  const persister = createIdbPersister()
  const { getters } = useStore()
  const uid = computed(() => getters.uid)

  const { data, isPending } = useQuery({
    queryKey: ['meta', { uid: uid.value, boardId: unref(boardId), projectId: unref(projectId) }],
    queryFn: () => getMeta({ boardId: unref(boardId), projectId: unref(projectId) }),
    persister
  })

  const invalidateMeta = () => {
    queryClient.invalidateQueries({ queryKey: ['meta', { boardId: unref(boardId) }] })
  }

  return {
    isLoading: isPending,

    departments: computed(() => data.value?.departments || []),
    locations: computed(() => data.value?.locations || []),
    spanOfControl: computed(() => data.value?.spanOfControl || []),
    roles: computed(() => data.value?.roles || []),
    depth: computed(() => data.value?.depth || 0),
    indirectConnections: computed(() => data.value?.indirectConnections || []),

    /**
     * List of { personId, name}
     */
    managers: computed(() => data.value?.managers || []),
    states: computed(() => data.value?.states || []),
    invalidateMeta
  }
}

export function useMetaHelper(boardId) {
  const queryClient = useQueryClient()

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['meta', { boardId: unref(boardId) }] })
  }

  return {
    invalidate
  }
}
