import * as Sentry from '@sentry/browser'
import { getComments, addComment, updateComment, deleteComment } from '@/services/comments-api.service.js'

/**
 * Function to reset state to default values
 */
const getDefaultState = () => {
  return {
    comments: {} // Array of Object with keys as BoardId
  }
}

const state = getDefaultState()

const getters = {
  comments: (_state) => (boardId) => {
    return _state.comments[boardId] || []
  }
}

const mutations = {
  setComments(_state, { boardId, comments }) {
    _state.comments[boardId] = comments
  },
  addComment(_state, { boardId, comment }) {
    if (!_state.comments[boardId]) _state.comments[boardId] = []
    _state.comments[boardId].push(comment)
  },
  updateComment(_state, { boardId, comment }) {
    const index = _state.comments[boardId].findIndex((c) => c.id === comment.id)
    if (index !== -1) {
      _state.comments[boardId][index] = comment
      return
    }
    _state.comments[boardId].push(comment)
  },
  deleteComment(state, { boardId, commentId }) {
    if (!state.comments[boardId]) return
    state.comments[boardId] = state.comments[boardId].filter(
      comment => comment.id !== commentId
    )
  }
}

const actions = {
  async fetch(context, { boardId }) {
    try {
      const comments = await getComments({ boardId })
      context.commit('setComments', { boardId, comments })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },
  addComment(context, { boardId, comment }) {
    try {
      const now = new Date()
      context.commit('addComment', {
        boardId,
        comment: { ...comment, updatedAt: now, createdAt: now }
      })
      // not waiting for the API call to complete as UI is already updated with the above commit
      addComment({ boardId, comment })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },
  updateComment(context, { boardId, comment }) {
    try {
      const now = new Date()
      context.commit('updateComment', {
        boardId,
        comment: { ...comment, updatedAt: now, createdAt: now }
      })
      // not waiting for the API call to complete as UI is already updated with the above commit
      updateComment({ boardId, comment })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },
    deleteComment(context, { boardId, commentId }) {
    try {
      // First update the UI optimistically
      context.commit('deleteComment', { boardId, commentId })
      
      // Then sync with the backend
      deleteComment({ boardId, commentId })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
