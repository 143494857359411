import OrgMapPreview from '@/assets/PagePreview/OrgMapPreview.svg'
import LayersAndDepthPreview from '@/assets/PagePreview/LayersAndDepthPreview.svg'
import LayersAndSpanOfControlPreview from '@/assets/PagePreview/LayersAndSpanOfControlPreview.svg'
import IciclePreview from '@/assets/PagePreview/IciclePreview.svg'
import HeadcountDistributionPreview from '@/assets/PagePreview/HeadcountDistributionPreview.svg'
import SpanPreview from '@/assets/PagePreview/SpanPreview.svg'
import AverageSpanPreview from '@/assets/PagePreview/AverageSpanPreview.svg'
import HeadcountCostDistributionPreview from '@/assets/PagePreview/HeadcountCostDistributionPreview.svg'
// import HeadcountCostPreview from '@/assets/PagePreview/HeadcountCostPreview.svg'
// import PlannedVsActualGrowthPreview from '@/assets/PagePreview/PlannedVsActualGrowthPreview.svg'
import HCGrowthPreview from '@/assets/PagePreview/HCGrowthPreview.svg'
// import HeadcountCostDistributionPreview from '@/assets/PagePreview/HeadcountCostDistributionPreview.svg'
// import PlannedVsActualCostPreview from '@/assets/PagePreview/PlannedVsActualCostPreview.svg'
import ImpactOfPlannedChangesPreview from '@/assets/PagePreview/ImpactOfPlannedChangesPreview.svg'
import PyramidPreview from '@/assets/PagePreview/PyramidPreview.svg'
import ProportionPreview from '@/assets/PagePreview/ProportaionChart.svg'
import ActivityEffortCategoryPreview from '@/assets/PagePreview/ActivityEffortCategoryPreview.svg'
import RoleEffortCategoryPreview from '@/assets/PagePreview/RoleEffortCategoryPreview.svg'
import SkillsHeatmapPreview from '@/assets/PagePreview/SkillsHeatmapPreview.svg'
import TablePreview from '@/assets/PagePreview/TablePreview.svg'

const HUB_DICT = {
  LAYER_AND_DEPTH: 'layerAndDepth',
  LAYER_AND_SPAN_OF_CONTROL: 'layerAndSpanOfControl',
  ORG_MAP: 'orgMap',
  HEADCOUNT_DISTRIBUTION: 'headcountDistribution',
  HEADCOUNT_COST_DISTRIBUTION: 'headcountCostDistribution',
  STACKED_HEADCOUNT_COST: 'stackedHeadcountCost',
  HEADCOUNT_GROWTH_DISTRIBUTION: 'headcountGrowthDistribution',
  SPAN_OF_CONTROL: 'spanOfControl',
  AVERAGE_SPAN_OF_CONTROL: 'averageSpanOfControl',
  ICICLE_CHART: 'icicleChart',
  IMPACT_OF_PLANNED_CHANGES: 'impactOfPlannedChanges',
  PYRAMID_CHART: 'pyramidChart',
  PROPORTION_CHART: 'proportionChart',
  ACTIVITY_EFFORT_CATEGORY: 'activityEffortCategory',
  ROLE_EFFORT_CATEGORY: 'roleEffortCategory',
  SKILLS_HEATMAP: 'skillsHeatmap',
  LAYERS_TABLE: 'layersTable'
}

const hubItems = {
  Orgmap: 'Orgmap',
  LayerAndDepth: 'Headcount heatmap',
  LayerAndSpanOfControl: 'Layers and span of control',
  HeadcountDistribution: 'Headcount distribution',
  HeadcountCostDistribution: 'Headcount cost distribution',
  StackedHeadcountCost: 'Stacked headcount cost distribution',
  HeadcountGrowth: 'Headcount growth',
  SpanOfControl: 'Span of control distribution',
  AverageSpanOfControl: 'Span of control',
  IcicleChart: 'Icicle chart',
  ImpactOfPlannedChanges: 'Impact of planned changes',
  PyramidChart: 'Pyramid chart',
  ProportionChart: 'Proportion chart',
  ActivityEffortCategory: 'Activity effort split by category',
  RoleEffortCategory: 'Role effort split by category',
  SkillsHeatmap: 'Skills heatmap',
  LayersTable: 'Layers Table',
  OrgChart: 'Org chart'
  // HeadcountGrowth: 'Headcount growth',
  // PlannedHeadcount: 'Planned vs actual headcount',
  // HeadcountCost: 'Headcount cost',
  // PlannedCost: 'Planned vs actual cost'
}

const hubItemDetails = [
  {
    name: hubItems.Orgmap,
    description: 'Employee distribution across your organization',
    previewImg: OrgMapPreview
  },
  {
    name: hubItems.LayerAndDepth,
    description: 'Hierarchy structure and distribution',
    previewImg: LayersAndDepthPreview
  },
  {
    name: hubItems.LayerAndSpanOfControl,
    description: 'Hierarchy structure and span of control distribution',
    previewImg: LayersAndSpanOfControlPreview
  },
  {
    name: hubItems.HeadcountDistribution,
    description: 'Headcount distribution across different groups',
    previewImg: HeadcountDistributionPreview
  },
  {
    name: hubItems.HeadcountCostDistribution,
    description: 'Headcount cost distribution across different groups',
    previewImg: HeadcountCostDistributionPreview
  },
  {
    name: hubItems.StackedHeadcountCost,
    description: 'Headcount cost by compensation components',
    previewImg: RoleEffortCategoryPreview
  },
  {
    name: hubItems.HeadcountGrowth,
    description: 'Headcount growth over time',
    previewImg: HCGrowthPreview
  },
  {
    name: hubItems.SpanOfControl,
    description: 'Manager distribution by span group',
    previewImg: SpanPreview
  },
  {
    name: hubItems.AverageSpanOfControl,
    description: 'Average span of control for different groups',
    previewImg: AverageSpanPreview
  },
  {
    name: hubItems.IcicleChart,
    description: "Bird's-eye view of your organization",
    previewImg: IciclePreview
  },
  {
    name: hubItems.ImpactOfPlannedChanges,
    description: 'Changes to your organization',
    previewImg: ImpactOfPlannedChangesPreview
  },
  {
    name: hubItems.PyramidChart,
    description: "Overview of your organization's shape",
    previewImg: PyramidPreview
  },
  {
    name: hubItems.ProportionChart,
    description: 'Proportional distribution of headcount',
    previewImg: ProportionPreview
  },
  {
    name: hubItems.ActivityEffortCategory,
    description: 'Activity effort split by category',
    previewImg: ActivityEffortCategoryPreview
  },
  {
    name: hubItems.RoleEffortCategory,
    description: 'Role effort split by category',
    previewImg: RoleEffortCategoryPreview
  },
  {
    name: hubItems.SkillsHeatmap,
    description: 'Skills heatmap',
    previewImg: SkillsHeatmapPreview
  },
  {
    name: hubItems.LayersTable,
    description: 'Layers Table',
    previewImg: TablePreview
  }

  // {
  //   name: hubItems.PlannedHeadcount,
  //   description: 'Headcount variance against scenario',
  //   previewImg: PlannedVsActualGrowthPreview
  // },
  // {
  //   name: hubItems.HeadcountCost,
  //   description: 'Headcount costs over time',
  //   previewImg: HeadcountCostPreview
  // },
  // {
  //   name: hubItems.PlannedCost,
  //   description: 'Headcount cost variance against scenario',
  //   previewImg: PlannedVsActualCostPreview
  // },
]

export { hubItems, hubItemDetails, HUB_DICT }
