import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import * as Sentry from '@sentry/browser'

const Mutations = {
  setBranding: 'setBranding',
  setLogo: 'setLogo',
  setWelcomeMessage: 'setWelcomeMessage'
}

/**
 * generate default state for the module
 */
const getDefaultState = () => {
  return {
    logo: null
  }
}

const state = getDefaultState()

const getters = {
  logo: (_state) => {
    return _state.logo
  }
}

const mutations = {
  [Mutations.setBranding](_state, branding) {
    _state.branding = branding
  },
  [Mutations.setLogo](_state, logo) {
    _state.logo = logo
  },
  [Mutations.setWelcomeMessage](_state, welcomeMessage) {
    _state.welcomeMessage = welcomeMessage
  }
}

const actions = {
  async fetch(context, { boardId }) {
    let { logo } = getDefaultState()

    context.commit(Mutations.setLogo, logo)

    if (!boardId) return

    try {
      await firebase
        .firestore()
        .collection('branding_config')
        .doc(boardId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            logo = data.logo || null
          }
        })

      context.commit(Mutations.setLogo, logo)
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  },

  async saveLogo(context, { boardId, logo }) {
    if (!boardId) return

    try {
      const collectionRef = firebase.firestore().collection('branding_config')

      const storageRef = firebase.storage().ref().child(`branding/logos/${boardId}`)
      await storageRef.put(logo)
      const url = await storageRef.getDownloadURL()

      context.commit(Mutations.setLogo, url)

      // Create a new document with id boardId
      await collectionRef.doc(boardId).set({
        logo: url
      })

      return
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
