<template>
  <nav class="absolute left-4 top-4 z-[60] flex w-fit flex-shrink-0">
    <section class="flex flex-1 items-start justify-between gap-4 pb-1">
      <section class="flex items-center gap-2 rounded-lg bg-white p-2 shadow-md">
        <!-- Logo -->
        <router-link :to="landingUrl">
          <Logo v-if="!logo" class="h-9 w-9" />
          <img v-else :src="logo" class="h-9 max-w-14 object-contain" />
        </router-link>

        <template v-if="boards && boards.length > 0">
          <!-- board selector dropdown -->
          <ScenariosDropdown
            :board-id="boardId"
            :project-id="projectId"
            class="z-[60]"
            data-test="main-org-plan-selector"
          />
        </template>

        <!-- Org, Directory, Forecast, Hub views -->
        <MainToolbarViews :views="views" :project-id="projectId" />
      </section>
    </section>
  </nav>
</template>

<script setup>
import Logo from '@/assets/Logo/Logomark.svg'
import AnalyticsIcon from '@/assets/untitled-ui/bar-chart-10.svg'
import ChartBreakout from '@/assets/untitled-ui/chart-breakout-square.svg'
import ListItemIcon from '@/assets/untitled-ui/list.svg'
import DataFlowIcon from '@/assets/untitled-ui/dataflow-04.svg'
import useBoard from '@/hooks/use-board'
import { useKeyboard } from '@/hooks/use-keyboard.js'
import useNavigation from '@/hooks/use-navigation'
import { MenuItems } from '@/utils/navigationData'
import { useMagicKeys } from '@vueuse/core'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import MainToolbarViews from './MainToolbarViews.vue'
import { useProjects } from '@/hooks/use-projects'
import { PLAN_VIEW_PAGES } from '@/lib/PlanViewRoutes'
import ScenariosDropdown from '@/components/ScenariosDropdown.vue'

const { navigationConfig } = useNavigation()
const router = useRouter()
const route = useRoute()
const { boardId } = useBoard()
const { getters } = useStore()
const { isUsingInput } = useKeyboard()
const { projects } = useProjects(boardId)

const landingUrl = computed(() => `/${boardId.value}`)
const boards = computed(() => getters.boards)
const logo = computed(() => getters['branding/logo'])
const projectId = computed(() => route.params.projectId)
const project = computed(() => projects.value?.find((project) => project.id === projectId.value))

const views = computed(() => {
  if (!projectId.value)
    return [
      {
        name: MenuItems.OrgPlan,
        icon: DataFlowIcon,
        tooltipText: 'Org Chart',
        dataTest: 'main-org-view-button',
        keyboardShortcut: '1'
      },
      {
        name: MenuItems.Directory,
        icon: ListItemIcon,
        tooltipText: 'Directory',
        dataTest: 'main-list-view-button',
        keyboardShortcut: '2'
      },
      {
        name: MenuItems.Forecast,
        icon: ChartBreakout,
        dataTest: 'main-forecast-view-button',
        tooltipText: 'Forecast',
        keyboardShortcut: '3'
      },
      {
        name: MenuItems.Hub,
        icon: AnalyticsIcon,
        dataTest: 'main-hub-view-button',
        tooltipText: 'Workforce Hub',
        keyboardShortcut: '4'
      }
    ]

  return [
    {
      name: 'Plan View',
      icon: DataFlowIcon,
      tooltipText: 'Org Chart',
      dataTest: 'main-org-view-button',
      keyboardShortcut: '1',
      handler: () =>
        router.push({ name: 'Plan View', params: { planId: project.value?.planBoardId } })
    },
    {
      name: MenuItems.Directory,
      icon: ListItemIcon,
      tooltipText: 'Directory',
      dataTest: 'main-list-view-button',
      keyboardShortcut: '2',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.List }
        })
    },
    {
      icon: ChartBreakout,
      dataTest: 'main-forecast-view-button',
      tooltipText: 'Forecast',
      keyboardShortcut: '3',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.Forecast }
        })
    },
    {
      name: MenuItems.Hub,
      icon: AnalyticsIcon,
      dataTest: 'main-hub-view-button',
      tooltipText: 'Workforce Hub',
      keyboardShortcut: '4',
      handler: () =>
        router.push({
          name: 'Plan View',
          params: { planId: project.value?.planBoardId },
          query: { mode: PLAN_VIEW_PAGES.Hub }
        })
    }
  ]
})

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (isUsingInput.value || e.type !== 'keydown') return

    const keyIndex = parseInt(e.key) - 1
    if (keyIndex >= 0 && keyIndex < views.value.length) {
      if (projectId.value) {
        views.value[keyIndex].handler()
      } else {
        const menuItem = views.value[keyIndex]

        router.push({ path: navigationConfig.value[menuItem.name]?.url })
      }
    }
  }
})
</script>
