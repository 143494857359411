import {
  getMapping,
  saveIntegrationMapping,
  getIntegrationMapping,
  updateIntegrationImportMapping,
  sync
} from '@/services/import-api.service.js'
import { updateSettings } from '@/services/settings-api.service.js'

//TODO: module without state - doesn't need to be wrapped in vuex
const actions = {
  async backendSync(context, { boardId, processId }) {
    return sync({ boardId, processId })
  },

  async fetchSavedImportMappings(context, boardId) {
    return getMapping({ boardId })
  },

  async fetchSavedIntegrationImportMappings(context, { boardId, type }) {
    return getIntegrationMapping({ boardId, type })
  },

  async setGoogleSheetsAutoSync(context, { boardId, autoSync }) {
    try {
      await updateSettings({ boardId, settings: { isGoogleSheetsAutoSyncOn: autoSync } })

      return {
        status: true,
        msg: 'Successfully saved'
      }
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async setIntegrationAutoSync(context, { boardId, type, autoSync }) {
    try {
      await updateIntegrationImportMapping({ boardId, type, autoSync })

      return {
        status: true,
        msg: 'Successfully saved'
      }
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async saveIntegrationImportMappings(context, { boardId, type, config, mapping, autoSync }) {
    await saveIntegrationMapping({ boardId, type, config, mapping, autoSync })
  }
}

export default {
  state: {},
  mutations: {},
  getters: {},
  actions,
  modules: {}
}
