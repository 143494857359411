import { computed, ref, toRaw, unref } from 'vue'
import { FilterType } from '@/lib/OrgChartFilters'
import { createGlobalState, until, useStorage, watchDebounced } from '@vueuse/core'
import { useStore } from 'vuex'
import { useMeta } from '@/hooks/use-meta'
import { useCustomFields } from '@/hooks/use-custom-fields'
import { useFilterStore, useFilterValuesStore } from '@/pinia/use-filter-store'
import { storeToRefs } from 'pinia'
import useBoard from '@/hooks/use-board'
import { useHierarchyState } from '@/hooks/use-hierarchy'
import { BaseHierarchy } from '@/lib/BaseHierarchy'
import { uniqBy } from 'lodash-es'
import { useWebWorker } from '@vueuse/core'
import FilterProcessingWorker from '@/workers/filter-processing.js?worker'

export const defaultOption = { name: 'Show all', type: '', id: '__default' }

const useState = createGlobalState(() => {
  const selectedFilters = useStorage('selected-filters', {})

  const setSelectedFilters = (key, newFilters) => {
    selectedFilters.value[key] = newFilters
  }

  return {
    selectedFilters,
    setSelectedFilters
  }
})

export const isDefault = (filters) =>
  (filters?.length === 1 && filters[0].id === '__default') || filters?.length === 0
export const isSelectedImpl = (filters, filter) => filters?.some((f) => f.id === filter.id)
export const setFilterImpl = (selectedFilters, newFilter) => {
  if (newFilter.type === '') {
    // If "Show all" is selected, clear all other selections
    return [defaultOption]
  }

  // If any other filter is selected, clear "Show all" and handle the rest
  let result = selectedFilters?.filter((item) => item.type !== '')

  if (isSelectedImpl(result, newFilter)) {
    result = result?.filter((item) => item.id !== newFilter.id)
  } else {
    result?.push(newFilter)
  }

  // If no filters are selected, set "Show all"
  if (result?.length === 0) {
    result = [defaultOption]
  }

  return result
}
export const resetFieldFiltersImpl = (selectedFilters, type) => {
  let result = selectedFilters?.filter((item) => item.typeId !== type && item.type !== type)

  if (result?.length === 0) {
    result = [defaultOption]
  }
  return result
}

export function useSelectedFilters(boardId, view = null) {
  const filterStore = useFilterStore()
  const { filterHistory } = storeToRefs(filterStore)
  const { updateFilterHistory } = filterStore
  const { selectedFilters: selectedFiltersPerView, setSelectedFilters: setFiltersState } =
    useState()

  const filterKey = computed(() =>
    unref(view) ? `${unref(boardId)}-${unref(view)}` : unref(boardId)
  )

  const selectedFilters = computed(() => selectedFiltersPerView.value[filterKey.value] || [])

  const setSelectedFilters = (newFilters) => {
    setFiltersState(filterKey.value, newFilters)
  }

  const resetFilters = () => {
    setSelectedFilters([defaultOption])
  }

  if (!selectedFilters.value) {
    resetFilters()
  }

  /**
   * Checks if an option is selected based on its ID.
   *
   * @param {Object} option - The option to check.
   * @param {string} option.id - The ID of the option.
   * @returns {boolean} - True if the option is selected, false otherwise.
   */
  const isSelected = (filter) => {
    return isSelectedImpl(selectedFilters.value, filter)
  }

  /**
   * Sets the selected filters based on the option selected by the user.
   * If "Show all" is selected, clears all other selections.
   * If any other filter is selected, clears "Show all" and handles the rest.
   *
   * @param {Object} option - The option selected by the user.
   * @param {string} option.type - The type of the option selected.
   * @param {string} option.id - The ID of the option selected.
   */
  const setFilter = (option) => {
    setSelectedFilters(setFilterImpl(selectedFilters.value, option))
  }

  const setManagerFilter = (managerId, managerName) => {
    const existingManagerFilter = selectedFilters.value?.filter(
      (filter) => filter.type === FilterType.Manager
    )

    if (existingManagerFilter.length === 1 && existingManagerFilter[0].personId === managerId) {
      setSelectedFilters(filterHistory.value[filterKey][managerId])
      return
    }

    updateFilterHistory(filterKey, managerId, selectedFilters.value)

    const existingFilters = selectedFilters.value?.filter(
      (filter) => filter.type !== FilterType.Manager
    )

    const managerFilter = {
      name: managerName,
      personId: managerId,
      type: FilterType.Manager,
      id: `manager-${managerId}`
    }

    existingFilters.push(managerFilter)
    setSelectedFilters(existingFilters)
  }

  /**
   * Construct the complex filter from existing base filters
   */
  const setComplexFilter = (filter) => {
    if (filter.type === 'managers') {
      //find managers with span of control over the value
      const filterToSet = filter.managers.map((manager) => {
        return {
          name: manager.name,
          type: FilterType.Manager,
          personId: manager.personId,
          id: manager.personId
        }
      })

      setSelectedFilters(filterToSet)
    }
  }

  const resetFieldFilters = (typeId) => {
    setSelectedFilters(resetFieldFiltersImpl(selectedFilters.value, typeId))
  }

  const selectedCountByFilterCategory = computed(() => {
    if (isDefault(selectedFilters.value)) return {}

    return selectedFilters.value.reduce((acc, filter) => {
      if (filter.typeId) {
        if (!acc[filter.typeId]) acc[filter.typeId] = []
        acc[filter.typeId].push(filter)
      } else {
        if (!acc[filter.type]) acc[filter.type] = []
        acc[filter.type].push(filter)
      }
      return acc
    }, {})
  })

  return {
    selectedFilters,
    setFilter,
    setSelectedFilters,
    setManagerFilter,
    setComplexFilter,
    resetFieldFilters,
    resetFilters,
    isSelected,
    selectedCountByFilterCategory
  }
}

const getCacheKey = (boardId, projectId) => `${boardId}-${projectId}`

// Add a Set to track which cache keys have been initialized
const initializedCacheKeys = new Set()

const { data, post, terminate } = useWebWorker(FilterProcessingWorker)

const createCachedValuesAndCategories = (boardId, projectId, isDirectory = false) => {
  const filterValuesStore = useFilterValuesStore()
  const { values, categories, unfilteredCategories } = storeToRefs(filterValuesStore)

  const cacheKey = getCacheKey(unref(boardId), unref(projectId))
  const computedValues = computed(() => values.value?.[cacheKey] || [])
  const computedCategories = computed(() => categories.value?.[cacheKey] || [])
  const computedUnfilteredCategories = computed(() => unfilteredCategories.value?.[cacheKey] || [])

  // Return early if we already have the cached values
  if (
    (values.value?.[cacheKey] &&
      categories.value?.[cacheKey] &&
      unfilteredCategories.value?.[cacheKey]) ||
    initializedCacheKeys.has(cacheKey)
  ) {
    return {
      values: computedValues,
      categories: computedCategories,
      unfilteredCategories: computedUnfilteredCategories
    }
  }

  // Only set up the watcher if we haven't initialized this cache key yet
  if (!initializedCacheKeys.has(cacheKey)) {
    initializedCacheKeys.add(cacheKey)

    const { getters } = useStore()
    const { boardId: mainBoardId } = useBoard()
    const { departments, roles, managers, locations, states, spanOfControl } = useMeta(
      boardId,
      projectId,
      isDirectory
    )

    const { uniqueValues, types: scenarioCustomFieldTypes } = useCustomFields(boardId, projectId)
    const { types: customFieldTypes } = useCustomFields(mainBoardId)
    const { hierarchyPerson } = useHierarchyState(boardId, BaseHierarchy)

    const sensitiveDataFields = computed(() => getters.sensitiveDataFields(unref(boardId)))
    const workforceStatusList = computed(() => ['approved', 'submitted', 'current employees'])
    const notMappedFields = computed(() => getters.notMappedFields(boardId))
    const fieldSettings = computed(() => getters.getFieldVisibility ?? {})

    const sensitiveDataValues = computed(() => {
      const ret = {}
      const sensitiveData = getters.sensitiveData(unref(boardId))

      for (let j = 0; j < sensitiveData.length; j++) {
        const obj = sensitiveData[j]
        for (let i = 0; i < obj.data.length; i++) {
          if (!(obj.data[i].field in ret)) {
            ret[obj.data[i].field] = []
          }
          if (!ret[obj.data[i].field].includes(obj.data[i].value)) {
            ret[obj.data[i].field].push(obj.data[i].value)
          }
        }
      }

      return ret
    })

    const isWorkerRunning = ref(false)

    const rootChildKey = hierarchyPerson.value?.['_root']?.children?.[0]?.id
    const rootDepth = hierarchyPerson.value?.[rootChildKey]?.depth
    const rootHeight = hierarchyPerson.value?.['_root']?.height

    // Set up the watch only once per unique boardId and projectId combination
    watchDebounced(
      [
        departments,
        roles,
        managers,
        locations,
        spanOfControl,
        states,
        sensitiveDataFields,
        workforceStatusList,
        notMappedFields,
        sensitiveDataValues,
        uniqueValues,
        customFieldTypes,
        fieldSettings,
        hierarchyPerson
      ],
      async () => {
        if (isWorkerRunning.value) {
          terminate()
        }

        const allTypes = [
          ...(scenarioCustomFieldTypes.value ?? []),
          ...(customFieldTypes.value ?? [])
        ]
        const allCustomFieldTypes = uniqBy(allTypes, 'id').map((entry) => toRaw(entry))

        isWorkerRunning.value = true

        // Debug each property before sending to worker
        const payload = {
          departments: toRaw(departments.value),
          roles: toRaw(roles.value),
          managers: toRaw(managers.value),
          locations: toRaw(locations.value),
          spanOfControl: toRaw(spanOfControl.value),
          states: toRaw(states.value),
          notMappedFields: toRaw(notMappedFields.value),
          workforceStatusList: toRaw(workforceStatusList.value),
          uniqueValues: toRaw(uniqueValues.value),
          allCustomFieldTypes: toRaw(allCustomFieldTypes),
          fieldSettings: toRaw(fieldSettings.value),
          boardId: unref(boardId),
          mainBoardId: unref(mainBoardId),
          sensitiveDataFields: toRaw(sensitiveDataFields.value),
          sensitiveDataValues: toRaw(sensitiveDataValues.value),
          rootDepth,
          rootHeight
        }

        try {
          post(payload)
          await until(data).changed()
        } catch (error) {
          console.error('Failed to post to worker:', error)
        }

        isWorkerRunning.value = false

        const { newValues, newCategories, newUnfilteredCategories } = data.value

        filterValuesStore.setValues(cacheKey, newValues)
        filterValuesStore.setCategories(cacheKey, newCategories)
        filterValuesStore.setUnfilteredCategories(cacheKey, newUnfilteredCategories)
      },
      { immediate: true, debounce: 2000 }
    )
  }

  return {
    values: computedValues,
    categories: computedCategories,
    unfilteredCategories: computedUnfilteredCategories
  }
}

/**
 * Hook that encapsulates filters categories and values business logic
 * Filter state is stored in useSelectedFilters hook
 */
export function useFilters(boardId, projectId = null) {
  const { values, categories, unfilteredCategories } = createCachedValuesAndCategories(
    unref(boardId),
    unref(projectId)
  )

  return {
    categories,
    values,
    unfilteredCategories
  }
}
